.section-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h1.sectionTitle {
    font-size: 1.75rem;
  }

  .section-header-action {
    cursor: pointer;
    &:hover {
      color: var(--text-color-hover);
    }
  }
  .section-header-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media (max-width: 800px) {
    h1.sectionTitle {
      font-size: 1.125rem;
      margin-bottom: 12px;
    }
  }
}
