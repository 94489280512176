.footer_container {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  margin-top: 56px;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color-xlight);
  .social_links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
    a {
      &:hover {
        color: var(--text-color-hover);
      }
      color: var(--text-color-xlight);
    }
  }
  .footer_line {
    &:hover {
      cursor: pointer;
      color: var(--text-color-hover);
    }
  }
}
@media (max-width: 600px) {
  .footer_container {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .social_links {
    gap: 8px;
  }
}
