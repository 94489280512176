.oauthFailed-container {
  .x {
    font-size: 14rem;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    font-size: 1.5rem;
    text-align: center;
  }
}
