.RafflePickerDetails-container {
  background-color: var(--bg-color-card);
  border-radius: var(--border-radius-large);
  padding: 24px;

  .RaffleWinnerTxnsRow-container {
    display: flex;
    margin-bottom: 4px;
    h1,
    h2 {
      flex: 1;
      font-size: 1rem;
    }
  }
}
