.Raffle-container {
  .raffledetails {
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      height: unset;
    }

    .slider-content {
      height: 100%;
    }
    .RewardItems-container {
      margin: 0;
      height: 100%;

      .raffleNft-Preview {
        position: relative;
        border-radius: var(--border-radius-large);
        overflow: hidden;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        a {
          box-sizing: border-box;
          display: block;
          position: absolute;
          bottom: 0;
          box-shadow: 0px 0px 50px 10px var(--box-shadow-color);
          background-color: var(--box-shadow-color);
          font-size: 1.5rem;
          font-weight: 300;
          color: var(--white);
          padding: 20px;
          width: 100%;
          @media (max-width: 800px) {
            padding: 20px;
            font-size: 1rem;
            // box-shadow: 0px 0px 30px 30px var(--box-shadow-color);
          }
        }
        a:hover {
          color: var(--white-hover);
        }
      }
    }

    // overriding loader
    .RewardItems-image-container-loader {
      .skeleton-loader-card {
        height: 100%;
      }
    }
  }
}
