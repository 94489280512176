.Inventory-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  .Categories-container {
    display: flex;
    overflow-x: auto;
    gap: 16px;
    padding-bottom: 8px;

    button {
      white-space: nowrap;
    }
  }

  .Inventory {
    width: 100%;
    max-height: 572px;
    overflow-y: auto;
    padding-top: 8px;
    margin-bottom: 0;
    overflow-y: auto;
    gap: 8px;
    align-self: start;
    grid-auto-rows: min-content;
    grid-template-rows: unset;

    flex: 1;

    @media (max-width: 800px) {
      max-height: unset;
      display: flex;
      overflow-y: auto;
    }

    .InventoryItem-container {
      position: relative;
      align-items: start;
      background-color: var(--bg-color-card);
      text-align: center;
      border: 1px solid transparent;
      border-radius: var(--border-radius-medium);
      padding: 16px;
      cursor: pointer;
      @media (max-width: 800px) {
        flex: 1;
        min-width: 100px;
      }
      img {
        width: 92px;
        height: 92px;
        object-fit: contain;
      }
      p {
        padding-top: 8px;
      }
      .lockIcon {
        position: absolute;
        bottom: 6px;
        right: 6px;
        font-size: 1.2rem;
      }
    }
    .InventoryItem-container:hover {
      border: 1px solid var(--primary-hover);
    }
    .InventoryItem-container.active {
      border: 1px solid var(--primary);
    }
    .InventoryItem-container.locked {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .InventoryItem-container.locked:hover {
      border: 1px solid transparent;
    }
  }
  footer {
    padding-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
    button {
      min-width: 140px;
      @media (max-width: 800px) {
        padding: 16px;
      }
    }
  }
}
