.Banners-container {
  .Banner-container {
    border-radius: var(--border-radius-xlarge);
    aspect-ratio: 3.17;
    padding: 60px 50px;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--white);

    @media (max-width: 800px) {
      border-radius: var(--border-radius-large);
      padding: 22px;
    }

    h1 {
      font-size: 2.2rem;
      margin-bottom: 6px;
      max-width: 28ch;
      @media (max-width: 800px) {
        font-size: 1.5rem;
        margin-bottom: 8px;
      }
      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }

    p {
      font-size: 1.05rem;
      line-height: 1.5rem;
      max-width: 48ch;
      @media (max-width: 800px) {
        font-size: 0.75rem;
        line-height: 1.1rem;
        font-weight: 300;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }

    .fillter {
      flex: 1;
    }

    a,
    button {
      padding: 10px 16px;
      border-radius: var(--border-radius-round);
      max-width: 150px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--black);
      background-color: var(--white);
      white-space: nowrap;
      margin-top: 12px;

      @media (max-width: 800px) {
        max-width: 100px;
        padding: 10px;
        font-size: 0.8rem;
        margin-top: 24px;
      }
      @media (max-width: 600px) {
        padding: 8px;
        font-size: 0.7rem;
      }
    }
    a:hover,
    button:hover {
      background-color: var(--white-hover);
    }
  }

  @media (max-width: 600px) {
    .sliderNav {
      display: none;
    }
  }
}
