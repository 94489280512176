.raffle-banner {
  background-color: var(--button-bg-color);
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  padding: 32px;
  border-radius: var(--border-radius);

  .raffle-banner_content {
    h1 {
      font-size: 1.5rem;
      margin-bottom: 8px;
    }

    p {
      font-size: 1.25rem;
      max-width: 80%;
    }
  }

  .raffle-banner-button {
    white-space: nowrap;
    padding: 12px 32px;
  }
}

@media screen and (max-width: 800px) {
  .raffle-banner {
    padding: 24px;
  }
}

@media screen and (max-width: 600px) {
  .raffle-banner {
    padding: 16px;

    .raffle-banner_content {
      h1 {
        font-size: 1.125rem;
        margin-bottom: 4px;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
}
