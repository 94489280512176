.RaffleTasks-container {
  .TaskCard-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    background: var(--bg-color-card);
    padding: 16px;
    padding-left: 32px;
    border-radius: var(--border-radius);
    margin-bottom: 16px;

    h1 {
      font-size: 1.15rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      .points {
        white-space: nowrap;
        color: var(--text-color-xxlight);
        font-size: 0.95rem;
      }
      a {
        white-space: nowrap;
      }
    }
  }

  .TaskCard-container.mobile {
    display: block;
    padding: 24px;

    .buttons {
      display: block;
      white-space: unset;

      h1,
      a,
      button {
        display: block;
      }

      .button {
        margin-top: 16px;
        padding: 16px;
      }
    }
  }
}
