.Quiz_container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;

  .panel {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 512px;
    flex-direction: column;
    width: 100%;
    @media (max-width: 600px) {
      justify-content: center;
      margin-bottom: 120px;
      overflow: auto;
    }
    .describe {
      margin-top: 76px;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 38px;
    }
    .option {
      display: flex;
      padding: 12px 10px;
      background: var(--theme-bg-black-dark);
      border-radius: 8px;
      cursor: pointer;
      align-items: center;
      text-align: start;

      &:hover {
        color: var(--text-color-hover);
      }

      &.selected {
        background: var(--button-bg-color);
      }

      &.disable {
        cursor: not-allowed;
      }
      .optionCircle {
        min-width: 35px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--theme-bg-gray);

        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  .action_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 24px;
    gap: 36px;
    .prevbtn {
      &.disabled {
        cursor: not-allowed;
      }
    }
    @media (max-width: 600px) {
      position: absolute;
      bottom: 0;
      padding: 16px;
    }
  }
}

.modal-container.quizPannelBox {
  max-width: 1200px;
  // padding: 0;
  border: none;
  .modalContent-container {
    max-height: 90vh;
  }
  .quizresult_container {
    &h2 {
      margin: 0;
      padding: 0;
    }
    width: 100%;
    height: 100vh;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @media (max-width: 600px) {
      height: auto;
    }
    .pass_container {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    .quiz_retry_container {
      width: 100%;

      display: flex;
      flex-direction: column;
    }
    .quiz_retry_img {
      width: 40%;
      @media (max-width: 600px) {
        width: 80%;
      }
    }
    .quiz_retry_child {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
