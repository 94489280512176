.modal-container-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media screen and (min-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.hideNavbar {
  z-index: 200;
}

.modal-container {
  min-width: 300px;
  max-width: 500px;
  scroll-behavior-block: contain;
  width: 100%;
  padding: 1rem;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  box-shadow: 0 2px 40px 0 var(--box-shadow-color);
  background: var(--bg-color);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media screen and (min-width: 800px) {
    border-radius: var(--border-radius-large);
    position: initial;
    margin: 0;
    padding: 2.5rem;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0 16px 42px 16px;
  }

  .mobile-closeBtn {
    display: block;
    width: 100px;
    height: 12px;
    border-radius: 8px;
    background-color: var(--bg-color-card);
    margin: 20px auto 42px auto;
    padding: 0;
  }

  .toolbar-closeBtn {
    position: absolute;
    width: 32px;
    max-width: 32px;
    height: 32px;
    top: 26px;
    right: 26px;
    cursor: pointer;
    z-index: 1;
    padding: 0;
    margin: 0;
    background-color: transparent;
    &:hover {
      opacity: 0.6;
    }
  }
  h2 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 8px;
  }
  h1 {
    font-size: 1.25rem;
    margin-bottom: 32px;
  }
  input {
    width: 100%;
    margin-bottom: 0px;
  }

  footer {
    display: flex;
    gap: 16px;
    align-items: start;
    margin-top: 32px;
    img {
      width: 42px;
      aspect-ratio: 1;
    }
    p {
      color: var(--text-color-light);
      font-size: 0.875rem;
    }
    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
