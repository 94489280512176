.previewSection {
  max-width: 100%;
  flex: 1;
  table {
    width: 100%;
    text-align: start;
    tr {
      width: 100%;
      td {
        padding: 0px 16px;
      }
      .img-preview {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
    tbody {
      tr:hover {
        background-color: #ffffff44;
      }
    }
  }
}
