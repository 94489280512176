.RaffleDetails-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .info {
    padding: 32px;
    max-height: 570px;
    overflow-y: auto;
    @media (max-width: 800px) {
      max-height: unset;
      padding: unset;
    }
    h1 {
      font-size: 3rem;
      margin-bottom: 20px;
      @media (max-width: 800px) {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 1.1rem;
      margin-bottom: 32px;
      @media (max-width: 800px) {
        font-size: 1rem;
        margin-bottom: 16px;
      }
    }

    h2 {
      font-size: 1.3rem;
      margin-bottom: 8px;
      @media (max-width: 800px) {
        font-size: 1rem;
        margin-bottom: 0px;
      }
    }
    .dates {
      margin-bottom: 32px;
      @media (max-width: 800px) {
        margin-bottom: 16px;
      }
      span {
        margin-right: 12px;
        font-size: 0.975rem;
        color: var(--text-color-light);
        @media (max-width: 800px) {
          font-size: 0.875rem;
        }
        b {
          margin-left: 6px;
          color: var(--text-color);
          font-size: 1rem;
          @media (max-width: 800px) {
            font-size: 0.925rem;
          }
        }
      }
    }
    .criteria {
      // font-size: 1.2rem;
      color: var(--text-color-light);
      line-height: 1.6rem;
      @media (max-width: 800px) {
        font-size: 0.95rem;
      }
    }
    .prizedescription {
      a {
        margin-bottom: 0;
        color: var(--text-color-light);
        margin-right: 8px;
      }
      a:hover {
        color: var(--text-color);
      }
    }
    .result-status-info {
      h1 {
        margin-bottom: 0px;
      }
    }
    .winnerText {
      line-height: 2.5rem;
    }
  }

  footer {
    margin-top: 16px;
  }

  .caption {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 800px) {
      font-size: 0.875rem;
    }
    i {
      margin-right: 4px;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
    padding: 18px;
  }

  .primary {
    width: 100%;
    font-weight: 800;
    font-size: 1.2rem;
    padding: 24px;
  }
  .explore {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
  }
}
