.mbadges-page {
  h2 {
    margin-bottom: 12px;
  }

  h3 {
    font-weight: 500;
  }
  .snapshot {
    color: var(--text-color-hover);
    a{
      text-decoration: underline;
    }
  }

  .page-title {
    font-family: 'Red Rose', serif;
    font-weight: 400;
    font-size: 3rem;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .banner-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: var(--border-radius-xlarge);
  }

  .info-grid {
    display: flex;
    gap: 30px;
    padding: 0 10px;
    margin: 50px 0;
    div {
      width: 80%;
    }

    @media (max-width: 800px) {
      div {
        width: 100%;
      }
      flex-direction: column;
      gap: 50px;
      padding: 0;
    }
  }

  .progress-stats-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 0 10px;
    @media (max-width: 800px) {
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  .your-stats-container {
    display: flex;
    width: 50%;
   justify-content: space-between;
   
    @media (max-width: 800px) {
      width: 100%;
    }

    span {
      font-size: 3.2rem;
      font-weight: 400;
    }

    span:nth-child(1) {
      color: var(--text-color-hover);
    }

    @media (max-width: 800px) {
      flex-direction: column;
      gap: 30px;

      span {
        font-size: 2rem;
      }
    }
  }

  .section-header-container {
    margin-top: 80px;

    h1 {
      margin-bottom: 0;
    }

    @media (max-width: 800px) {
      margin-top: 40px;
      // margin-bottom: 12px;
    }
  }
  .heading_bar {
    margin-top: 80px;
    display: flex;
    gap: 32px;
    align-items: center;

    h1 {
      font-size: 28px;
      cursor: pointer;
    }
    .active_heading {
      text-decoration: underline;
      text-decoration-color: var(--text-color-hover);
      text-decoration-thickness: 4px;
      text-underline-offset: 14px;
    }
  }
}
