.UserDropdown-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  img {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
  button,
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: none;
    padding: 0;
    background-color: transparent;
    text-align: left;
    font-size: 1rem;
    white-space: nowrap;
    border-radius: 0;
    width: 100%;
  }
  a:hover {
    border-bottom: none;
  }

  .tooltip-content {
    min-width: 16ch;
    overflow: hidden;
    button,
    a {
      padding: 16px 32px;
      font-weight: 600;
    }
    button:hover,
    a:hover {
      background-color: var(--bg-color-card);
    }
    .logout {
      color: var(--alert-color);
    }
  }
}
.UserDropdown-container.ToolTip-container {
  .tooltip-content {
    padding: 10px 0px;
  }
}
.modal-container.UserDropdown-container {
  display: block;
}
