.mbadge_slider {
  width: 100%;
  height: 60vh;
  max-height: 800px;
  margin-bottom: 1rem;
  margin-top: 3rem;
  .tap_to_next{
    display: none;
  }
  @media (max-width: 800px) {
    min-height: 80vh;
    height: auto;
    max-height: none;
  }
  .banner_layout {
    width: 100%;
    height: 60vh;
    max-height: 600px;
    font-family: 'Rethink Sans';
    // background: #1a1a1a;
    background-color: var(--bg-color-card);
    border-radius: var(--border-radius-xlarge);
    @media (max-width: 800px) {
      min-height: 80vh;
      height: 100%;
      overflow: hidden;
      max-height: none;
      .tap_to_next{
        display: block;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
      }
    }

    .orange_color {
      color: var(--text-color-hover);
    }
    .cursor_class {
      width: 100px;
      height: auto;
      padding: 8px 16px;
      background-color: var(--primary);
      color: var(--text-color-hover);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      border-radius: var(--border-radius-large);
    }
    .cursor_negative {
      background-color: var(--bg-color);
    }
    .layout_1 {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      gap: 3rem;
      @media (max-width: 800px) {
        min-height: 80vh;
      }
      .not_elig {
        width: 25%;
      }
      .nft_container {
        padding: 1rem;
        box-sizing: border-box;
        width: 100%;
        @media (max-width: 800px) {
          display: flex;
          flex-direction: column;
          grid-template-columns: repeat(1, minmax(300px, 1fr)) !important;
          gap: 24px;
        }
        h1 {
          font-size: 2rem;
          margin-bottom: 8px;
          @media (max-width: 800px) {
            font-size: 1.25rem;
            margin-bottom: 2px;
          }
        }
        h2 {
          font-size: 1.75rem;
          margin-bottom: 12px;
          @media (max-width: 800px) {
            font-size: 1.25rem;
            margin-bottom: 2px;
          }
        }
        p {
          font-size: 1rem;
          line-height: 1.75rem;
          margin-bottom: 32px;
          white-space: wrap;
          @media (max-width: 800px) {
            font-size: 0.95rem;
            line-height: unset;
            margin-bottom: 16px;
          }
        }
        .action_button {
          // height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          gap: 24px;
        }
        a,
        button {
          font-size: 1.2rem;
          font-weight: 600;
          flex: 1;
          min-width: 32%;
          padding: 32px;

          @media (max-width: 800px) {
            font-size: 1rem;
            padding: 24px 16px;
            white-space: wrap;
          }
        }

        button:disabled {
          color: var(--primary);
        }
        .clickable {
          display: flex;
          justify-content: center;
          gap: 8px;
          align-items: center;
          background-color: var(--button-bg-color-dark);
        }
        .nftprofile_logo {
          width: 30px;
          height: 30px;
        }
        .clickable:hover {
          background-color: var(--button-bg-color-dark-hover);
        }
      }
      .circle_1 {
        position: absolute;
        top: 5%;
        left: 15%;
      }
      .circle_2 {
        position: absolute;
        top: 10%;
        right: 15%;
      }
      .circle_3 {
        position: absolute;
        bottom: 3rem;
        left: 10rem;
      }
      h1 {
        width: 70%;
        text-align: center;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.05rem;
        @media (max-width: 600px) {
          width: 100%;
          font-size: 2rem;
        }
      }

      .button {
        background: var(--text-color-hover);
        color: var(--text-color);
      }
    }
    .layout_2 {
      width: 100%;
      height: 100%;
      display: flex;
      .white_bg {
        background-color: white;
      }
      .section_1 {
        width: 50%;
        height: 100%;
        padding: 3rem;
        display: flex;
        box-sizing: border-box;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 0 var(--border-radius-xlarge);
        h1 {
          font-size: 2.5rem;
        }
        .chains_container {
          width: 100%;
          height: 40%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 15%;
          img {
            width: 10%;
          }
        }
        .chain_count {
          font-size: 2rem;
        }

        .chain_number {
          font-size: 12rem;
          font-style: normal;
          font-weight: 500;
        }
        img {
          width: 100%;
        }
        .claimed_attestation {
          h3 {
            margin-top: 20px;
          }
        }
        @media (max-width: 600px) {
          .small_text {
            font-size: 1.5rem;
          }
        }
      }
      .section_2 {
        width: 50%;
        height: 100%;
        background-color: var(--text-color-hover);
        border-radius: 0 var(--border-radius-xlarge) var(--border-radius-xlarge) 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        box-sizing: border-box;
        .most_done {
          font-size: 8rem;
        }
        .inner {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          box-sizing: border-box;
          .badges {
            display: flex;
            padding: 1rem 2rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 3.83494rem;
            font-size: 18px;
            font-style: normal;
            border: 2px solid #fbf7f4;
          }
          .margin_right {
            margin-right: 20%;
          }

          h2 {
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 600;
          }
          h3 {
            font-size: 1.5rem;
          }
        }
        .align_center {
          align-items: center;
          justify-content: center;
          gap: 5%;
        }
        .star_svg {
          position: absolute;
          bottom: 124px;
          left: 50px;
        }
        h1 {
          font-size: 13rem;
          font-style: normal;
          font-weight: 500;
          z-index: 2;
        }
      }
      @media (max-width: 1000px) {
        .section_2 {
          h1 {
            font-size: 6rem;
          }
          .inner {
            .badges {
              padding: 1rem;
              font-size: 24px;
            }
          }
        }
      }
      @media (max-width: 600px) {
        flex-direction: column;
        .section_1 {
          padding: 1rem;
          width: 100%;
          height: 50%;
        }
        h1 {
          font-size: 2rem;
        }
        .section_2 {
          width: 100%;
          height: 50%;
          border-radius: 0 0 var(--border-radius-xlarge) var(--border-radius-xlarge);
          .star_svg {
            bottom: 24px;
            left: 10px;
          }
          h1 {
            font-size: 4rem;
          }
          .inner {
            justify-content: flex-end;
            .badges {
              padding: 1rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
    .layout_3 {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
      gap: 3rem;
      padding: 3rem;
      color: var(--text-color-hover);
      box-sizing: border-box;
      background-color: white;
      border-radius: var(--border-radius-xlarge);
      h1 {
        width: 100%;
        font-size: 4rem;
        font-style: normal;
        letter-spacing: -0.15rem;
      }
      h2 {
        width: 40%;
      }
      .shine_like {
        font-size: 5rem;
      }
      .arrow_svg {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        svg {
          width: 80%;
        }
      }
      .blue_star {
        position: absolute;
        bottom: 10%;
        right: 10%;
        svg {
          width: 60%;
        }
      }
      @media (max-width: 1000px) {
        .famouns_attestation {
          font-size: 4rem;
          letter-spacing: 0rem;
          width: 100%;
        }
      }
      @media (max-width: 600px) {
        padding: 1rem;
        .blue_star {
          right: 2rem;
          bottom: 3rem;
        }
        .arrow_svg {
          display: none;
        }
        h1 {
          width: 100%;
          font-size: 2rem;
        }
        h2 {
          width: 80%;
        }
        .famouns_attestation {
          font-size: 4rem;
          letter-spacing: 0rem;
        }
        .shine_like {
          font-size: 3rem;
          letter-spacing: 0rem;
        }
      }
    }
  }
  .preview_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    overflow: hidden;
    box-sizing: border-box;
    gap: 5%;

    .nft {
      overflow: hidden;
      flex: 1;
      border-radius: var(--border-radius-xlarge);

      iframe {
        height: 50vh;
        max-height: 550px;
        video {
          object-fit: cover;
        }
      }
    }
    @media (max-width: 800px) {
      flex-direction: column;
      gap: 16px;
      padding-left: 2rem;
      .nft {
        height: 400px;
        iframe {
          height: 400px;
        }
      }
      .nft_action {
        width: 100%;
      }
    }
    .nft_action {
      box-sizing: border-box;
      padding: 1rem;
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      .action_button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          width: 40%;
        }
        a {
          color: var(--bg-color);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          gap: 12px;
          &:hover {
            color: var(--text-color-hover);
          }
        }
        @media (max-width: 800px) {
          flex-direction: column;
          gap: 32px;
          button {
            width: 100%;
          }
        }
      }
      .button_container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
