:root {
  --border-radius-small: 2px;
  --border-radius-medium: 6px;
  --border-radius: 12px;
  --border-radius-large: 20px;
  --border-radius-xlarge: 28px;
  --border-radius-round: 999px;
}

body {
  color: var(--text-color);
  background: var(--bg-color);
}
body.modal-open {
  overflow: hidden;
}

.App {
  max-width: 1180px;
  margin: 0px auto;
  padding: 0px 16px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
  transition: 220ms;
}
a:hover {
  color: var(--text-color-hover);
}
a:hover h2 {
  color: var(--text-color);
}
a.link {
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 1px;
}
a.link:hover {
  border-bottom: 1px solid var(--text-color-hover);
}

section {
  margin-bottom: 72px;

  @media (max-width: 800px) {
    margin-bottom: 42px;
  }
}

// h1.sectionTitle {
//   font-size: 1.75rem;
//   margin-bottom: 24px;

//   @media (max-width: 800px) {
//     font-size: 1.125rem;
//     margin-bottom: 12px;
//   }
// }

.button,
button {
  border: none;
  border-radius: var(--border-radius-round);
  padding: 12px 24px;
  cursor: pointer;
  background-color: var(--button-bg-color);
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-color);

  transition: 220ms;

  @media (max-width: 600px) {
    padding: 8px 14px;
    font-size: 0.8rem;
  }
}
.button:hover,
button:hover {
  background-color: var(--button-bg-color-hover);
}
.button:disabled,
button:disabled {
  cursor: unset;
  opacity: 0.5;
}

.button.primary,
button.primary {
  background-color: var(--primary);
  color: var(--secondary);
}
.button.primary:hover,
button.primary:hover {
  background-color: var(--primary-hover);
}
.button.primary:disabled,
button.primary:disabled {
  background-color: var(--primary);
}
.button.secondary,
button.secondary {
  background-color: var(--secondary);
  color: var(--primary);
}
.button.secondary:hover,
button.secondary:hover {
  background-color: var(--secondary-hover);
}
.button.secondary:disabled,
button.secondary:disabled {
  background-color: var(--secondary);
}
.button.themed,
button.themed {
  background-color: var(--text-color-hover);
  color: var(--primary);
}
.button.themed:disabled,
button.themed:disabled {
  background-color: var(--secondary);
}

.text-center {
  text-align: center;
}

.ellipse {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card {
  box-shadow: none;
  transition: 200ms;
  text-decoration: unset;
  color: unset;
  background-color: var(--bg-color-card);
  border-radius: var(--border-radius);
  overflow: hidden;

  @media (max-width: 600px) {
    border-radius: var(--border-radius);
  }
  .img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    background-size: cover;
    background-position: center;

    @media (max-width: 600px) {
      height: 150px !important;
    }
  }
  .content {
    // box-shadow: 0px 3px 22px 4px var(--box-shadow-color);
    padding: 20px;

    @media (max-width: 600px) {
      padding: 10px 16px 16px 16px;
    }
    h1 {
      font-size: 1.25rem;
      margin-bottom: 10px;
      font-weight: 500;
      @media (max-width: 600px) {
        margin-bottom: 4px !important;
        font-size: 1rem;
      }
    }
    p {
      color: var(--text-color-light);
      font-size: 0.9rem;
      font-weight: 400;
      @media (max-width: 600px) {
        font-size: 0.875rem;
      }
    }
    footer {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      color: var(--text-color-light);
      font-size: 0.9rem;
      font-weight: 400;

      @media (max-width: 600px) {
        gap: 10px;
        font-size: 0.875rem;
      }
    }
  }
}
a.card:hover {
  // box-shadow: 0px 4px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(0, 0, 0, 0.24) 0px 1px 2px;
  // scale: 1.005;
  box-shadow: inset 0 -3.5px 0 0 var(--text-color-hover);
}

input {
  width: 70%;
  outline: none;
  padding: 6px 8px;
  font-size: 1.25rem;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--bg-color-card);
  margin-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    font-size: 1.15rem;
    padding: 10px;
  }
}
input:focus {
  border: 1px solid var(--primary);
}

.infinite-scroll-observer {
  width: 1px;
  height: 1px;
  margin-bottom: 5px;
}

/* Hiding scrollbars but still allow scrolling */
.no-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.custom-react-select {
  width: 300px;
}
.md-24 {
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
}
