.EventAnalytics-container {
  width: 100%;

  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    margin-left: 64px;
  }

  .chart {
    width: 100%;
    margin-bottom: 24px;
    h1 {
      margin-left: 64px;
    }

    .ChartTooltip-container {
      max-width: 200px;
      .labelSuffix {
        margin-left: 4px;
      }
      h2 {
        font-size: 16px;
        font-weight: 700;
        color: #ffffffbb;
      }
      h1 {
        margin: unset;
        color: white;
        .label {
          font-size: 16px;
          font-weight: 600;
          margin-left: 8px;
          color: white;
        }
      }
    }
  }

  .UserProperties-container {
    .useravatar {
      width: 32px;
      aspect-ratio: 1;
      border-radius: 4px;
    }
  }

  .TasksDone-container {
    .hover-tools-container:hover {
      opacity: unset !important;
      cursor: unset;
    }
    td {
      .info-tooltip {
        background-color: #303030;
        box-shadow: 0px 0px 16px #000000;
      }
      .UserDetails-container {
        h1 {
          font-weight: 900;
          font-size: 16px;
        }
        .contractRow {
          display: flex;
          gap: 16px;
          margin-bottom: 8px;
          font-size: 14px;
          img {
            width: 22px;
            aspect-ratio: 1;
            border: 1px solid #131313;
          }
        }
      }
    }
  }
  .TaskDoneStats-container {
    margin-top: 64px;
  }
}
