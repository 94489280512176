.Raffles-container {
  .RaffleCard-container {
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--bg-color-card);
    padding: 22px;
    display: flex;
    flex-direction: column;
    text-decoration: none;

    @media (max-width: 600px) {
      padding: 10px;

      .img {
        height: 100px !important;
      }
    }
    .img {
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: var(--border-radius-medium);
      margin-bottom: 8px;
    }
    h2 {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin-bottom: 8px;
      @media (max-width: 600px) {
        font-size: 0.875rem;
        margin-bottom: 0;
      }
      span {
        margin-right: 4px;
        font-size: 1.5rem;
        margin-bottom: -1px;
      }
      span.ended {
        color: var(--alert-color);
      }
      span.live {
        color: var(--success-color);
      }
      font-size: 0.875rem;
    }
    h1 {
      margin-bottom: 8px;
      @media (max-width: 600px) {
        font-size: 1rem;
        margin-bottom: 14px;
      }
    }
    p {
      flex: 1;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      color: var(--text-color-light);
      @media (max-width: 600px) {
        font-size: 0.95rem;
      }
    }
  }
}

.raffles-page {
  grid-template-columns: repeat(2, 1fr) !important;
}

.no-raffle-text {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 40px 0;
}
