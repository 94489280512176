.custom-skeleton-loader {
  margin: 20px 0;

  .react-loading-skeleton {
    border-radius: var(--border-radius-medium);
  }
}
.skeleton-loader-table-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.skeleton-loader-cards-container {
  width: 100%;
  display: grid;
  gap: 20px;

  .skeleton-loader-card {
    // min-height: 150px;
    height: 150px;
    span {
      height: 100%;
    }
  }
}
