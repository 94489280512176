.TaskVerifyResultToast-container {
  h1 {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
    color: var(--text-color-light);
  }
  .rewardItems {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    img {
      flex: 1;
      width: 36px;
      height: 36px;
      padding: 8px;
      object-fit: contain;
      border-radius: var(--border-radius-medium);
      background-color: var(--bg-color-card);
    }
  }
}
