.table-container {
    width: 100%;
    max-width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border-radius: var(--border-radius);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #131313;
  
    overflow: auto;
  
    .table {
      display: block;
      width: 100%;
      border: none;
      color: #eaeaea;
  
      thead {
        width: 100%;
        display: block;
        border: none;
      }
      tbody {
        width: 100%;
        display: block;
        border: none;
      }
  
      tr {
        display: flex;
        width: 100%;
        border: none;
        align-items: center;
        background-color: #131313;
      }
      tr:nth-child(even) {
        background-color: #202020;
      }
  
      td,
      th {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 0.85rem;
        padding-bottom: 0.85rem;
        text-align: left;
        flex: 1;
        border: none;
        overflow: hidden;
        min-width: 15ch;
      }
  
      .td-small {
        flex: 0.25;
        min-width: 10ch;
      }
  
      .td-medium {
        flex: 0.5;
        min-width: 20ch;
      }
    }
    .table-pagination-container {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      font-size: 0.7rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      button {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        background-color: transparent;
        color: var(--text-color);
        min-width: 0 !important;
        width: fit-content;
      }
    }
    .loader-component-container {
      padding-top: 15vh;
      padding-bottom: 15vh;
    }
  }
  