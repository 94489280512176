.toggle-container {
  height: fit-content;

  .checkbox {
    display: none;
  }

  .checkbox-label {
    background-color: var(--toggle-bg-color);
    width: 50px;
    height: 22px;
    border-radius: 50px;
    position: relative;
    padding: 2px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkbox-label-theme {
    background-color: var(--toggle-bg-color);
    width: 30px;
    height: 30px;
    border-radius: var(--border-radius-medium);
    position: relative;
    padding: 2px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-moon {
    margin-left: 4px;
    margin-bottom: 2px;
    line-height: 1;
  }

  .icon-sun {
    margin-right: 4px;
    margin-bottom: 2px;
    line-height: 1;
  }

  .checkbox-label .ball {
    background-color: var(--button-bg-color);
    width: 20px;
    height: 20px;
    position: absolute;
    left: 2.5px;
    top: 3px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }

  .checkbox:checked + .checkbox-label .ball {
    transform: translateX(28px);
  }
}

@media (max-width: 800px) {
  .toggle-theme-container {
    scale: 0.8;
  }
}

@media (max-width: 380px) {
  .toggle-theme-container {
    display: none;
  }
}
