$main-color : var(--text-color-hover);

.referal_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6rem;
  margin-bottom: 10rem;

  .description_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;

    .description {
      display: flex;
      flex-direction: column;
      padding: 10px;


      h1 {
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .text {
        margin-top: 1.1rem;
        font-optical-sizing: auto;
      }

      a {
        color: var(--text-color-hover);
        text-decoration: underline;
      }
    }

    img {
      border-radius: var(--border-radius-xlarge);
      max-width: 50%;
    }

    @media (max-width : 700px) {
      flex-direction: column;

      img {
        max-width: 100%;
        border-radius: var(--border-radius-large);
        align-self: flex-start;
        order: 0;
      }

      .description {
        order: 1;
      }
    }
  }


  .claim_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      unicode-bidi: isolate;
      margin-bottom: 0.5rem;
    }

    p {
      max-width: 75%;
    }

    .claims {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 18px;

      .each_tile {
        min-width: 45%;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: var(--border-radius);
        display: flex;
        flex-wrap: wrap;
        background: var(--bg-color-card);
        justify-content: space-between;

        @media (max-width : 700px) {
          min-width: 100%;
        }

        .img-and-amount {
          margin-left: 5%;
          display: flex;
          justify-content: space-between;
          gap: 0.8rem;
          align-items: center;
        }

        img {
          height: 52px;
          width: 52px;
          aspect-ratio: 1;
          object-fit: contain;
          border: 0.2rem solid $main-color;
          border-radius: 50%;

          @media (max-width: 900px) {
            height: 32px;
            width: 32px;
          }
        }

        .button {
          background-color: var(--text-color-hover);
          margin-right: 1rem;
          min-width: 25%;
          padding: 0.2rem;
        }

        .amount {
          display: flex;
          gap: 8px;
          align-items: center;
          font-size: 1rem;
          color: #6d6d6d;

          span {
            font-size: 2rem;
            color: var(--text-color-hover);
          }
        }
      }
    }
  }

  .stats_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media (max-width : 700px) {
      flex-wrap: wrap;
    }

    .stats {
      h2 {
        font-size: 1.5em;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      display: flex;
      flex-direction: column;
      gap : 0.2rem;

      .each_stat {
        span {
          color: var(--text-color-hover);
          font-size: 3.2rem;
          font-weight: 400;
          line-height: 6.0625rem;
          margin-top: 1rem;
        }

        display: flex;
        flex-direction: column;
      }

      .stats-footer {
        font-size: 1.17em;
        font-weight: 500;
      }
    }

    .table-container {
      height: 100%;
      background-color: inherit;
      margin-top: 0px;
      padding-top: 0px;
      max-width: 60%;



      @media (max-width : 700px) {
        max-width: 100%;
        margin-top: 6rem;
      }


      h2 {
        margin-bottom: 1rem;
        font-size: 1.5em;
        font-weight: bold;
        unicode-bidi: isolate;
      }


      table {
        width: 100%;
        background-color: rgba($color: grey, $alpha: 0.2);
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 12px;
        overflow: hidden;

        th {
          border: 0.01rem solid rgba(0, 0, 0, 0.219);
          background-color: var(--text-color-hover);
          padding: 12px;
        }

        td {
          font-size: 1rem;
          color: var(--text-color-light);
          padding: 12px;
          text-align: start;
          border-bottom: 0.01rem solid rgba(128, 128, 128, 0.452);
          border-collapse: collapse;
        }
      }
    }
  }
}