.AdminVerifyTaskSubmissions-container {
  .api-cards {
    display: flex;
    flex-wrap: wrap;
    // gap: 20px;

    border-radius: 4px;
    border: 1px solid #6e6e6e;

    .searchInputBox {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 16px;
      input {
        width: 100%;
        flex: 1;
        height: fit-content;
        margin-right: 20px;
      }
      button {
        margin: 0;
        width: auto;
      }
    }

    .tableBox {
      width: 100%;
      .col-edit {
        min-width: 70px;
        // width: 30px;
      }
      thead {
        width: 100%;
        border: 1px solid #6e6e6e;
        td {
          padding: 16px;
        }
        td:last-child {
          min-width: 30px;
        }
      }
      tbody {
        tr {
          // &:not(:last-child) {
          //   border-bottom: 1px solid #6e6e6e;
          // }
          border-bottom: 1px solid #6e6e6e;
          td:not(:last-child) {
            padding: 24px 16px;
          }
        }
      }
      .actionButton {
        width: 30px;
        height: 30px;
        object-fit: contain;
        flex: none;
        cursor: pointer;
      }
      .state {
        border-radius: 16px;
        padding: 6px 10px;
      }
      .PENDING {
        border: 1px solid #ffe500;
      }
      .VERIFIED {
        border: 1px solid #396ff8;
      }
      .REJECTED {
        border: 1px solid #f00;
      }
    }

    .pageBox {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      .pageAction {
        display: flex;
        margin-left: 26px;
        .arrowButton {
          width: 24px;
          height: 24px;
          padding: 12px;
          flex: none;
          box-sizing: content-box;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .rightArrow {
          transform: rotate(180deg);
        }
      }
    }

    .card {
      background-color: #f0f0f0;
      border-radius: 4px;
      padding: 10px;
      width: 500px;
      margin: 10px;

      h2 {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        color: #333;
      }
    }
  }
}

.modal-container.SubmitListPannelBox {
  max-width: 1200px;
  max-height: 600px;
  padding-left: 0;
  padding-right: 0;
  padding: 0;
  padding-top: 16px;
}
