.petcat-button {
  background-color: var(--text-color-hover);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  padding-right: 8px;
  border-radius: var(--border-radius-xlarge);
  cursor: pointer;
  h3 {
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 800;
    gap: 4px;
    p {
      font-weight: 400;
    }
  }
  &:hover {
    background-color: var(--text-color-hover);
  }
  span {
    font-size: 18px;
    background-color: black;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tooltip-content.pet-cat-modal {
    display: flex;
    padding: 18px;
    padding-left: 18px;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    width: 400px ;
    justify-content: space-between;
    box-shadow: 0 2px 12px 0 var(--box-shadow-color);
    span{
      background: none;
    }
   
  }
  .pet-cat-description {
    width: 90%;
    text-align: left;
    font-size: 12px;
  }

  .negative-point-container{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 15px;
    gap: 4px;
    span{
      color: red;
    }
  }
  .cat-gif-back{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    border-radius:var(--border-radius-xlarge) ;
    color :black;
    padding: 8px;
    box-sizing: border-box;
    ul{
      list-style-type: none;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 8px;
      padding-left:12px ;
     span{
      background: none;
      font-size: 24px;
     }
     li{
      display: flex;
      align-items: center;
      gap: 8px;
     }
    }
  }
  .cat-gif{
    width: 50%;
  }
  .pet-cat-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-container{
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .info-container{
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 18px;
    }
     
    span {
      font-size: 28px;
      width: 35px;
      height: 35px;
    }
    h1 {
      margin: 0;
      font-size: 22px;
      display: flex;
      font-weight: 700;
      align-items: center;
      gap: 8px;
      p {
        font-weight: 400;
      }
    }
  }
  .pet-cat-footer{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    justify-content: space-between;
    .error{
      color: red;
    }
  }
  .pet-cat-modal-button {
    width: 250px;
    background-color: var(--text-color-hover);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    border-radius: var(--border-radius-xlarge);
    &:hover {
      background-color: var(--text-color-hover);
    }
    &:disabled {
      background-color: var(--bg-color-card);
      cursor: not-allowed;
    }
  }
}

@media (max-width: 600px) {
  .petcat-button {
    padding: 0;
    background: none;
  }
  .pet-cat-modal {
    width: auto;
  }
  .cat-gif{
    display: flex;
    justify-content: center;
    width: 100%;
  }
}