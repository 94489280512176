.Grid-container {
  display: grid;
  gap: 30px;

  align-items: stretch;
  grid-auto-rows: minmax(50px, auto);

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(130px, 1fr)) !important;
    gap: 16px !important;
  }
}
