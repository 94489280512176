.EasyTasks-container.Grid-container {
  @media (max-width: 1500px) {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (max-width: 600px) {
    display: flex;
    overflow-x: auto;
    gap: 10px;
  }

  .EasyTaskCard-container {
    border-radius: var(--border-radius-large);
    border-radius: var(--border-radius);
    padding: 20px;

    background: var(--bg-color-card);

    @media (max-width: 800px) {
      flex: 1;
      padding: 18px 16px;
      min-width: 96px;
    }

    img {
      width: 64px;
      aspect-ratio: 1;
      object-fit: contain;
      margin-bottom: 16px;
      padding: 12px;
      background: var(--bg-color);
      border-radius: var(--border-radius);

      @media (max-width: 800px) {
        width: 36px;
        margin-bottom: 16px;
        padding: 8px;
        margin-bottom: 14px;
      }
    }

    .content {
      background-color: unset;
      box-shadow: unset;
      padding: unset;
    }

    h1 {
      margin-bottom: 8px;
      @media (max-width: 800px) {
        font-size: 1rem;
        margin-bottom: 2px;
      }
    }
  }
}
