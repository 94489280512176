.ToolTip-container.UserProgress {
  .tooltip-content {
    top: 6px;
    margin-top: 0;
    min-width: 120px;
    text-align: center;
    right: 100%;
    margin-right: 8px;
  }
}
.ToolTip-container.UserProgress.horizontal {
  .tooltip-content {
    top: -16px;
    margin-top: 0;
    min-width: 120px;
    text-align: center;
    right: 100%;
  }
}

.UserProgress-container {
  width: 16px;
  height: 100%;
  border-radius: var(--border-radius-medium);
}
.UserProgress-container.horizontal {
  width: 42px;
  min-width: 42px;
  max-width: 42px;
  height: 16px;
}
