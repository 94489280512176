.modal-container.loginPanel {
  .loginOption {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    position: relative;
    padding: 16px 20px;

    h1 {
      font-size: 1.1rem;
      font-weight: 500;
      flex: 1;
      text-align: left;
      margin: 0;
    }
    .spinner {
      position: absolute;
      right: 0;
      margin-right: 22px;
      font-size: 2rem;
    }
    .icon {
      height: 30px;
      width: 30px;
    }
  }
  footer {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 32px;
    img {
      width: 42px;
      aspect-ratio: 1;
    }
    p {
      color: var(--text-color-light);
      font-size: 0.875rem;
    }
  }
}
