.time-filter-container {
    width: 100%;
    display: flex;
    align-items: center;
    .filter-buttons {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }
    
    .filter-button {
        width: 40px;
        height: 40px;
        background-color: #919aa4;
        color: white;
        border: none;
        border-radius: 10px;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .filter-button:hover {
        background-color: #0056b3;
    }
    
    .custom-date-container {
        display: flex;
        // flex-direction: column;
        align-items: center;
    }
    
    .custom-date {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .custom-date label {
        margin-bottom: 5px;
    }
    
    .custom-date input {
        padding: 5px;
        font-size: 14px;
    }
    
}

