.SimpleSlider-container {
  position: relative;
  .sliderNav {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    background: none;
    font-size: 8rem;
    color: var(--white);
    opacity: 0.4;

    z-index: 10;
  }
  .sliderNav:hover {
    opacity: 1;
  }
  .sliderNav.left {
    left: 0;
  }
  .sliderNav.right {
    right: 0;
  }

  .slider-content {
    display: flex;
    width: 100%;
    height: auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: smooth;

    .slide {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      scroll-snap-align: start;
      overflow: hidden;
    }
  }

  .slider-dots {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 8px;
    .slider-dot {
      transition: all 220ms ease;
      border-radius: 50%;
      width: 0px;
      height: 0px;
      aspect-ratio: 1;
      padding: 6px;
      background-color: var(--text-color-xxlight);
    }
    .primary {
      background-color: var(--text-color);
    }
  }

  /* Hiding scrollbars but still allow scrolling */
  .no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}
