.adminContainer-container {
    color: var(--text-color);
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
  
    .links {
      display: flex;
      gap: 1rem;
      flex-basis: 100%;
      a:hover {
        opacity: 0.6;
      }
    }
  
    section {
      padding: 2rem;
      border-radius: var(--border-radius-large);
      border: 1px solid var(--border-color);
      background-color: var(--theme-black-opacity);
      width: fit-content;
  
      h1,
      h2 {
        color: var(--text-color-light);
        margin: 1rem 0;
        font-size: 1.5rem;
        font-weight: 900;
      }
      h2 {
        font-size: 1.25rem;
      }
    }
  
    button {
      display: block;
      width: 100%;
      background: var(--text-color-hover);
      padding: 8px 16px;
      border-radius: var(--border-radius);
      margin: 1rem 0;
    }
    button:hover {
      opacity: 0.6;
    }
  
    input,
    textarea {
      width: 100%;
      // background-color: var(--bg-color-panel);
      padding: 6px 12px;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid var(--main-color);
    }
    .label {
      color: var(--text-color);
      font-size: 0.85rem;
      margin-top: 16px;
    }
  }
  