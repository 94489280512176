.slideUpOverlay {
  animation: slideUpOverlay-anim ease-out;
  animation-fill-mode: forwards;
}

.slideDownOverlay {
  animation: slideDownOverlay-anim ease-in;
  animation-fill-mode: forwards;
}

@keyframes slideUpOverlay-anim {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDownOverlay-anim {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

.backdropEnterOverlay {
  animation: backdropEnterOverlay-anim ease-out;
  animation-fill-mode: forwards;
  z-index: 81;
}

.backdropExitOverlay {
  animation: backdropExitOverlay-anim ease-in;
  animation-fill-mode: forwards;
  z-index: 81;
}

@keyframes backdropEnterOverlay-anim {
  from {
    /* opacity: 0; */
    backdrop-filter: blur(0px);
  }

  to {
    /* opacity: 1; */
    backdrop-filter: blur(2px);
  }
}

@keyframes backdropExitOverlay-anim {
  from {
    /* opacity: 1; */
    backdrop-filter: blur(2px);
  }

  to {
    /* opacity: 0; */
    backdrop-filter: blur(0px);
  }
}

@keyframes spinner-anim {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.fade-animation {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}