.Settings-container {
  .sectionTitle {
    font-size: 1.25rem;
    margin-bottom: 16px;
  }

  .SocialLogins-container {
    grid-template-columns: repeat(2, minmax(250px, 1fr)) !important;
    grid-auto-rows: minmax(60px, auto);
    gap: 18px;

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }

    .SocialLoginItem-container {
      display: flex;
      align-items: center;
      position: relative;
      background-color: var(--bg-color-card);
      border-radius: var(--border-radius-medium);
      padding: 20px 20px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 16px;
      }
      h1 {
        flex: 1;
        font-size: 1.15rem;
        font-weight: 400;

        @media (max-width: 800px) {
          font-size: 0.95rem;
        }
      }

      button {
        position: absolute;
        right: 16px;
        z-index: 1;
        display: none;
        font-weight: 600;
        font-size: 0.875rem;
      }
      button.disconnect {
        color: var(--white);
        background-color: var(--alert-color);
      }
      button.disconnect:hover {
        background-color: var(--alert-color-hover);
      }
    }
    .SocialLoginItem-container:hover {
      button {
        display: block;
      }
    }
  }
  .logout {
    color: var(--alert-color);
    font-weight: 500;
  }
}
