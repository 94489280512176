// src/AnimatedButton.scss
.animated-button {
  background-color: var(--text-color-hover);
  border: none;
  border-radius: var(--border-radius-round);
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  width: 120px;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  &:hover {
    background-color: var(--text-color-hover);
  }

  &.clicked {
    width: 150px;
    background-color: white;
    color: var(--text-color-hover);
    padding-right: 20px;
    height: 56px;

    .arrow {
      display: flex;
      align-items: center;
      height: 100%;
      animation: moveRight 0.5s forwards;
    }
  }

  .arrow {
    position: absolute;
    left: 10px;
    top: 0px;
  }
}

@keyframes moveRight {
  0% {
    left: 10px;
  }
  100% {
    left: calc(110px);
  }
}
