.TopCommunities-container {
  @media (max-width: 900px) {
    display: flex !important;
    overflow-x: auto;
    gap: 10px;
  }
  .TopCommunityCard-container {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: var(--bg-color-card);
    border-radius: var(--border-radius);
    padding: 20px;
    padding-right: 32px;

    @media (max-width: 900px) {
      flex: 1;
      min-width: 260px;
      padding: 16px;
      padding-right: 16px;
    }
    img {
      height: 52px;
      width: 52px;
      aspect-ratio: 1;
      object-fit: contain;
      @media (max-width: 900px) {
        height: 32px;
        width: 32px;
      }
    }
    .content {
      flex: 1;

      background-color: unset;
      box-shadow: unset;
      padding: unset;

      h1 {
        font-size: 1.25rem;
        margin-bottom: 8px;
        font-weight: 500;

        @media (max-width: 900px) {
          font-size: 1rem;
          margin-bottom: 2px;
        }
      }
      p {
        @media (max-width: 900px) {
          font-size: 0.875rem;
        }
      }
    }
    .rewards {
      img {
        display: inline-block;
        width: 32px;
        object-fit: contain;
        aspect-ratio: 1;

        @media (max-width: 900px) {
          width: 24px;
        }
      }
    }
  }
}
