.ToolTip-container {
  position: relative;
  .tooltip-content {
    width: fit-content;
    position: absolute;
    display: block;
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
    border: 1px solid var(--bg-color-card);
    top: 100%;
    right: 0;
    padding: 12px;
    margin-top: 16px;
  }
  .tooltip-content.not-show {
    display: none;
  }
}
.ToolTip-container.hoverable:hover {
  .tooltip-content {
    display: block;
  }
}
