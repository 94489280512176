.Me-container {
  .ViewAll {
    width: 100%;
    border-radius: var(--border-radius-medium);
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color-xlight);
  }

  .MyActivity-container {
    .user-activity-chart-area {
      path {
        fill: var(--bg-color-card);
        stroke: var(--text-contrast);
      }
    }

    .chart-tooltip-box {
      z-index: 900;
      padding: 6px;
      border-radius: 6px;
      border: 1px solid var(--bg-color-card);
      background-color: var(--bg-color);
    }
  }

  .MyNfts-container {
    .NftCard-container {
      .content {
        padding-top: 20px;
      }
      h1 {
        font-size: 1.15rem;
      }
    }
    .ViewAll {
      border-radius: var(--border-radius);
      font-size: 1.75rem;
    }
  }

  .RewardHistory-container {
    .reward-history-actions {
      margin-bottom: 20px;
      display: flex;
      justify-content: end;
    }

    .RewardRow-container {
      display: flex;
      align-items: center;
      gap: 32px;
      margin-bottom: 8px;
      background-color: var(--bg-color-card);
      border-radius: var(--border-radius-medium);
      padding: 16px 16px;

      .title {
        flex: 1;
        max-width: 600px;
        h1 {
          font-size: 1.15rem;
          margin-bottom: 2px;
        }
        p {
          font-size: 0.875rem;
          color: var(--text-color-xxlight);
        }
      }
      .assets {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        img {
          width: 32px;
          height: 32px;
          aspect-ratio: 1;
          border-radius: var(--border-radius-small);
          object-fit: contain;
          overflow: hidden;
          padding: 8px;
          background-color: var(--bg-color-card);
        }
      }
      .xp {
        height: fit-content;
        font-weight: 800;
        text-align: right;
      }

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: unset;
        gap: 24px;
        .xp {
          text-align: left;
        }
      }
    }
  }
}
