.AdminAddEvent-layout {
  display: flex;
  width: 100%;

  form{
    min-height: 600px;
  }
  .sidebar {
    width: 250px;
    background-color: #2f2f2f;
    padding: 20px;
    box-sizing: border-box;


    button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      color: #fff;
      background-color: #5f5f5f;
      border: none;
      cursor: pointer;
      text-align: left;
      &:hover {
        background-color: #414141;
      }
    }
  }
  .formcontainer {
    background-color: rgb(27, 26, 26);
    padding: 15px;
    width: 100%;
    .rewards-container {
      position: relative;
      height: 450px;
      .addButton{
        background-color: #1647ce;
        margin: 1px;
        position: relative;
        bottom: 0;
      }
    }
    .bottom-textarea {
      height: 300px;
    }
    .rewardsitem-display{
      display: grid;
      grid-template-columns: 1fr 1fr;
      .removeButton{
        background-color: #c10000;
        margin: 1px;
        float: right;
        position: absolute;
        right: 0;
        
      }
    }
    .eventRewards{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

    }
    .removeButton {
      background-color: #c10000;
      float: right;
    }
    .addButton {
      background-color :#1647ce;
    }
    ::placeholder {
      color: #999;
      font-style: italic;
    }
    input {
      width: 100%;
      padding: 4px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid rgb(141, 141, 141);
      margin-bottom: 20px;
      padding: 5px;
      color: rgb(255, 255, 255);
      background-color: rgb(39, 39, 39);

    }

    input[type="submit"] {
      width: 30%;
      padding: 10px; 
      height: 50px; 
      border-radius: 8px; 
      border: 2px solid #4CAF50; 
      margin-bottom: 20px; 
      color: #fff; 
      background-color: #16408a; 
      cursor: pointer; 
      transition: background-color 0.3s ease;
      position: relative;
      bottom: 0px;
  }
  input[type = "checkbox"]{
    width: 30px;
    height: 20px; 
    border-radius: 8px; 
    margin-bottom: 20px; 
    color: #242424; 
    cursor: pointer; 
    transition: background-color 0.3s ease;
    position: relative;
    bottom: 0px;
  }
  
  input[type="submit"]:hover {
      background-color: #080a09;
  }

  
    .inventory-config-container {
      .radio-button-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label {
          margin: 10px;
          color: rgb(255, 255, 255);
          display: flex;
          align-items: center;
        }

        input[type="radio"] {
          margin: 0;
          width: 15px;
          height: 15px;
        }
      }
    }
    .removeButton{
      background-color: #c10000;
    }

    button {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      color: rgb(252, 252, 252);
      border-radius: 5px;
      padding: 5px;
      max-width: fit-content;
    }

    h1 {
      font-size: 15px;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 13px;
      margin-bottom: 2px;
      font-weight: bold;
    }

    select {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid rgb(0, 0, 0);
      margin-bottom: 20px;
      padding: 5px;
      color: rgb(255, 255, 255);
      background-color: rgb(39, 39, 39);
    }

    textarea {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      border: 1px solid rgb(0, 0, 0);
      margin-bottom: 20px;
      padding: 5px;
      color: rgb(163, 163, 163);
      background-color: rgb(39, 39, 39);
    }
  }
}
