.communitycreate-container {
    background: var(--bg-color);
    height: 100vh;
    display: flex;
    justify-content: center;
    .content {
      max-width: 50ch;
      text-align: center;
      margin-top: 15vh;
      .logo {
        margin-bottom: 1rem;
        max-height: 3rem;
      }
      h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 1.15rem;
        color: var(--text-color-dark);;
      }
      .label {
        font-size: 0.7rem;
        font-weight: bold;
      }
      button {
        margin-top: 1.8rem;
        width: 100%;
        background-color: var(--text-color-hover);
        padding: 8px;
        border-radius: var(--border-radius);
      }
      input {
        border-radius: var(--border-radius);
        padding: 8px 16px;
       background-color: white;
      }
    }
  }
  