.CustomCursorSection {
    position: relative;
    height: 100%;
    width: 100%;
    cursor: none;

    .custom-cursor {
        position: absolute;
        pointer-events: none; // Ensure the cursor does not interfere with clicks
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .content {
        padding: 20px;
        text-align: center;
    }
}
