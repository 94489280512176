.AfterLoggedIn-container-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 40px;
    width: 400px;
  }

  @media (max-width: 600px) {
    padding-top: 100px;

    img {
      margin-bottom: 30px;
      width: 200px;
    }
  }
}
.AfterLoggedIn-container-title.sectionTitle {
  margin-bottom: 16px;
  // margin-top: 20%;
  text-align: center;
}

.AfterLoggedIn-container {
  text-align: center;

  p {
    font-size: 1.5rem;
    color: var(--text-color-light);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 600px) {
    p {
      font-size: 1.1rem;
      max-width: 80%;
    }
  }
}
