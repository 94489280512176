.NftPreview-container {
  position: relative;
  iframe {
    border: none;
    margin: none;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  }

  .minted-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--text-color-hover);
    font-size: 0.7rem;
    text-align: center;
    padding: 2px;
  }
}

a:hover {
  .minted-tag {
    color: var(--text-color);
  }
}
