.input-container {
    text-align: left;
    margin-bottom: 16px;
  
    .label {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    input,
    textarea {
      width: 100%;
      font-size: 16px;
      padding: 6px 18px;
      background-color: white;
      border-radius: 4px;
      outline: none;
    }
    input::placeholder,
    textarea::placeholder {
      opacity: 0.6;
    }
  }
  
  .postfix-input {
    flex: 1;
    margin-top: 0;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    .icon-container {
      width: 16px;
      height: 16px;
    }
    .postfix-container {
      display: flex;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius);
      overflow: hidden;
      input {
        border: none;
      }
      .postfix {
        font-size: 0.8rem;
        padding-left: 0.85rem;
        padding-right: 1.25rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: var(--border-color);
        white-space: nowrap;
      }
    }
    .postfix-container:hover {
      border-color: var(--border-color-darker);
    }
    .postfix-container:focus-within {
      border-color: var(--main-color);
    }
  }
  
  .otpInput-container .otp-inputs {
    display: flex;
    gap: 1rem;
  }
  .SubmitListPannel-container {
    .tableBox {
      width: 100%;
      thead {
        width: 100%;
        border-bottom: 1px solid #6e6e6e;
        td {
          padding: 16px;
        }
      }
      tbody {
        tr {
          // &:not(:last-child) {
          //   border-bottom: 1px solid #6e6e6e;
          // }
          border-bottom: 1px solid #6e6e6e;
          td:not(:last-child) {
            padding: 24px 16px;
          }
        }
      }
      .actionButton {
        width: 30px;
        height: 30px;
        object-fit: contain;
        flex: none;
        cursor: pointer;
      }
      .state {
        border-radius: 16px;
        border: 1px solid #ffe500;
        padding: 6px 10px;
      }
    }
    .infoBox {
      border-bottom: 1px solid #6e6e6e;
      display: flex;
      .avatarBox {
        width: 480px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #6e6e6e;
        flex: none;
        img {
          height: 338px;
          object-fit: contain;
        }
      }
      .verifyList {
        width: 100%;
        max-height: 340px;
        padding-bottom: 20px;
        overflow-y: auto;
        .listItem {
          padding: 20px 40px 20px 18px;
          margin-bottom: 4px;
          display: flex;
          background-color: #303030;
          h1 {
            flex: 0.4;
          }
          h2 {
            flex: 1;
          }
        }
      }
    }
    .actionBox {
      padding: 30px 0;
      display: flex;
      gap: 45px;
      justify-content: center;
      .actionButton {
        padding: 12px;
        min-width: 190px;
        border-radius: 8px;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.034px;
        cursor: pointer;
        border: 1px solid #6e6e6e;
        &:hover {
          opacity: 0.8;
        }
        &.reject {
          border: 1px solid #f00;
          color: #f00;
          &:hover {
            background: #f00;
            color: var(--text-color-dark);
          }
        }
        &.approve {
          border: 1px solid #396ff8;
          color: #396ff8;
          &:hover {
            background: #396ff8;
            color: var(--text-color-dark);
          }
        }
      }
    }
    .SubmitCheckBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px; /* 131.25% */
      }
      .info {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 143%; /* 20.02px */
        letter-spacing: 0.15px;
        margin-top: 16px;
      }
      .state {
        margin-top: 27px;
  
        font-feature-settings: "clig" off, "liga" off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: 143%; /* 20.02px */
        letter-spacing: 0.15px;
        &.REJECT {
          color: #f00;
        }
        &.APPROVE {
          color: #396ff8;
        }
      }
    }
  }
  
  