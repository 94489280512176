.score_animation{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  .entry{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3rem;
    h2{
      width: 50%;
      font-size:1.25rem ;
    }
    @media (max-width: 1000px){
      h2{
        width: 100%;
      }
      flex-wrap: wrap;
      gap: 1rem;
    }
    @media (max-width: 800px) {
      h2{
        width: 100%;
        font-size: 1rem;
      }
      flex-wrap: wrap;
      gap: .5rem;
    }
  }
  @media (max-width: 800px) {
    h2{
      width: 100%;
      font-size: 1rem;
    }
    flex-wrap: wrap;
    gap: .5rem;
  }

}