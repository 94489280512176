.analytics_graph {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 200px;
}
.charts_graph {
  width: 100%;
  height: auto;
  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .toggle {
      display: flex;
      align-items: center;
      width: max-content;
    }
  }
}
