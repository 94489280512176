.Level-container {
  margin-bottom: 40px;
  // display: flex;
  // flex-direction: column;
  // gap: 500px;
  .TaskCard-container {
    background: var(--bg-color-card);
    padding: 24px;
    padding-left: 32px;
    border-radius: var(--border-radius);
    margin-bottom: 16px;
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;

      h1 {
        font-size: 1.15rem;
        font-weight: 600;
        margin-bottom: 0;
      }

      .buttons {
        display: flex;
        align-items: center;
        gap: 16px;

        .points {
          white-space: nowrap;
          color: var(--text-color-xxlight);
          font-size: 0.95rem;
        }
      }
    }
    .task-content {
      margin-top: 16px;
      font-size: 0.95rem;
      .description {
        margin-bottom: 16px;
      }
      h1 {
        font-size: 0.95rem;
        margin-bottom: 6px;
      }
      ol {
        margin: 8px 0;
      }
    }
  }
  .TaskCard-container.mobile {
    padding: 24px;
    header {
      display: block;
      justify-content: center;
      align-items: center;
    }
    .description {
      margin-top: 16px;
      color: var(--text-color-light);
    }
    .buttons {
      margin-top: 16px;
      white-space: unset;

      button {
        flex: 1;
        padding: 16px;
      }
    }

    @media (max-width: 600px) {
      .buttons {
        button {
          padding: 10px;
        }
      }
    }
  }
}
.quiz-disabled {
  cursor: not-allowed;
  background-color: var(--alert-color);
  &:hover {
    background-color: var(--alert-color-hover);
  }
}

@keyframes boxShadowAnimation {
  0% {
    box-shadow: none;
    transform: scale(1);
  }
  50% {
    box-shadow:
      rgba(0, 0, 0, 0.12) 0px 1px 3px,
      rgba(0, 0, 0, 0.24) 0px 1px 2px,
      inset 0 -3.5px 0 0 var(--text-color-hover);
    transform: scale(1.005);
  }
  100% {
    box-shadow: none;
    transform: scale(1);
  }
}
.highlight {
  animation: boxShadowAnimation 2s ease-in-out;
}
