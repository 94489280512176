.NotFound404-container {
  text-align: center;
  img {
    max-width: 60%;
    object-fit: contain;
  }
  h1 {
    font-size: 2.75rem;
    margin-bottom: 8px;
    color: var(--text-color-light);
  }
  p {
    color: var(--text-color-xlight);
    font-size: 1.25rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8rem;
    margin-bottom: 24px;
    font-weight: 500;
  }
  .button {
    padding: 20px 64px;
    font-size: 1.35rem;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
  }
}
