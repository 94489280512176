.RaffleWinnerCard-container.card {
  h1 {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 2px;
  }
  .link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border: none;
  }
}
