.toggle-container {
    margin-bottom: 20px;

    h1 {
        margin-bottom: 10px; 
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
    input {
        display: none; 
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 6px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
        }
    }
    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }
    input:checked + .slider:before {
        transform: translateX(32px);
    }
}
