.toggle-theme-container {
  height: fit-content;

  .theme-checkbox {
    display: none;
  }

  .checkbox-label {
    background-color: var(--toggle-bg-color);
    width: 50px;
    height: 22px;
    border-radius: 50px;
    position: relative;
    padding: 2px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkbox-label-theme {
    background-color: var(--bg-color);
    width: 30px;
    height: 30px;
    border-radius: var(--border-radius-medium);
    position: relative;
    padding: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkbox:checked.checkbox-label-theme {
    background-color: black;
  }
}

@media (max-width: 800px) {
  .toggle-theme-container {
    scale: 0.8;
  }
}

@media (max-width: 380px) {
  .toggle-theme-container {
    display: none;
  }
}
