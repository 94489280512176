.modal-container.TaskVerifyForm-container {
  max-width: 400px;
  input {
    padding: 12px 16px;
    min-width: none;
  }
  footer {
    button {
      width: 100%;
      max-width: 240px;
      font-size: 1rem;
    }
  }
}
