.Navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 32px 14px;
  position: sticky;
  top: 0;
  background-color: var(--bg-color);
  margin-bottom: 26px;
  z-index: 100;
  // extra padding and negative margin to handle edgecases when cards scale up
  margin-left: -50px;
  margin-right: -50px;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 1500px) {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 600px) {
    padding: 14px;
    // padding-top: unset;
    // padding-bottom: unset;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    @media (max-width: 600px) {
      gap: 10px;
      align-items: baseline;
    }

    .hamburger {
      background: none;
      padding: 0px;
      font-size: 2rem;
      padding-right: 4px;
    }
    .logo {
      height: 30px;
      object-fit: contain;

      img {
        height: 100%;
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .logoName {
      font-size: 1.5rem;
      margin-right: 28px;
      margin-top: -4px;
      @media (max-width: 600px) {
        font-size: 1.5rem;
        margin-right: unset;
        margin-top: unset;
      }
      @media (max-width: 380px) {
        display: none;
      }
    }

    .menu {
      margin-left: 28px;
      font-size: 1.125rem;
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 20px;

    .referral-container {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      font-size: 1.35rem;
      padding: 0;
      background-color: unset;

      span {
        font-size: 1rem;
      }
    }
    .referral-container:hover {
      color: var(--text-color-hover);
    }
  }
}

.Hamburger-container {
  a {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 12px;

    font-size: 1.25rem;
    font-weight: 600;

    .icon {
      font-size: 1.5rem;
    }
  }
}
.referal_modal_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  margin-top: 24px;
  color: var(--text-color);
  h1 {
    font-size: 1.78938rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0 !important;
  }
  h2 {
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
  }
  .social_icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 24px;
    padding: 16px;
    padding-left: 0;
    padding-right: 0;

    .each_social {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;
      gap: 8px;
      border-radius: var(--border-radius-large);

      .icon_button {
        height: 60px;
        width: 60px;

        border-radius: var(--border-radius-large);
        &:hover {
          background-color: var(--text-color-hover);
        }
        padding: 16px;
        @media (max-width: 600px) {
          height: 60px;
          width: 60px;
        }
      }
      .social_icon {
        width: 30px;
        height: 30px;
      }
      h2 {
        margin: 0;
      }
    }
  }
  .referal_link_container {
    width: 100%;
    box-sizing: border-box;
    height: 56px;
    gap: 36px;
    display: flex;
    align-items: center;
  
    justify-content: space-between;

    .link {
      width: 100%;
      height: 56px;
      display: flex;
      padding: 16px;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.75rem;
      box-sizing: border-box;
      align-items: center;
      cursor: pointer;
      background-color: var(--button-bg-color-dark-hover);
      border-radius: var(--border-radius-medium);
      color: var(--text-color-xlight);
    }
  }
  .copy_button {
    background-color: var(--text-color-hover);
    border-radius: var(--border-radius-medium);
    width: 36px;
  }
  .link_to {
    width: 100%;
    color: var(--text-color-hover);
    font-size: 1.1355rem;
    font-weight: 600;
    line-height: 1.98713rem;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
  }
}
