.GreenTick {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2px;
  height: 2px;
  font-size: 1rem;
  background-color: var(--success-color);
  color: var(--text-contrast);
  border-radius: 50%;
  padding: 12px;
}
