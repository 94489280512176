.AllEvents-container {
  gap: 26px;
  margin-bottom: 32px;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.view-more {
  width: 100%;
  border-radius: var(--border-radius-medium);
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color-xlight);
  margin-bottom: 72px;

  .spinner {
    background: var(--text-color-xlight) !important;
  }
}
.spacer {
  margin-bottom: 72px;
}
