.MintNft-container {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  // justify-content: space-between;
  .info {
    // padding: 32px;
    max-height: 570px;
    overflow-y: auto;

    ul {
      margin: 0;
      @media (max-width: 800px) {
        padding-left: 16px;
      }
      li {
        font-size: 1rem;
        margin-bottom: 4px;
        @media (max-width: 800px) {
          font-size: 0.95rem;
        }
      }
    }
  }

  .username-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: 800px) {
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      margin-bottom: 0;
      font-size: 1rem;
      padding: 20px;
      border-radius: var(--border-radius-medium);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-weight: 500;
      @media (max-width: 800px) {
        font-size: 1rem;
        padding: 12px;
      }
    }
    .suffix {
      background-color: var(--bg-color-card);
      padding: 20px;
      border-radius: var(--border-radius-medium);
      border-top-left-radius: var(--border-radius-small);
      border-bottom-left-radius: var(--border-radius-small);
      font-weight: 500;
      white-space: nowrap;
      @media (max-width: 800px) {
        padding: 12px;
      }
    }
  }

  .usernameCaption {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    @media (max-width: 800px) {
      margin-bottom: 4px;
      margin-top: 16px;
    }
    h1.username {
      font-size: 2.5rem;
      text-align: right;
      margin-bottom: 0;
      color: var(--text-color-light);
      @media (max-width: 800px) {
        font-size: 1.5rem;
      }
    }
    p {
      margin-bottom: 0;
    }
  }

  .set-name-caption-container {
    display: flex;
    justify-content: space-between;
  }

  .mintcaption {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;

    .dynamic-fee-tooltip {
      cursor: pointer;

      .tooltip-content {
        width: 300px;
      }
    }
    @media (max-width: 800px) {
      font-size: 0.875rem;
    }
    i {
      margin-right: 4px;
    }
  }

  .primary {
    width: 40%;
    font-weight: 600;
    font-size: 1rem;
    padding: 16px;

    @media (max-width: 800px) {
      font-size: 1rem;
      padding: 10px;
    }
  }
}
