.MyStats-container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 12px;

  .StatCard-container {
    text-align: center;
    flex: 1;

    border-radius: var(--border-radius);
    padding: 20px;

    background: var(--bg-color-card);

    @media (max-width: 800px) {
      padding: 16px 10px 10px 10px;
      border-radius: var(--border-radius-medium);
    }

    h1 {
      margin-bottom: 8px;
      font-size: 3rem;
      padding: 16px;
      min-width: 2ch;
      background: var(--bg-color);
      border-radius: var(--border-radius);
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;

      @media (max-width: 800px) {
        margin-bottom: 8px;
        padding: 16px 10px 10px 10px;
        font-size: 1.5rem;
        padding: 10px;
        border-radius: var(--border-radius-medium);
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      @media (max-width: 800px) {
        gap: 4px;
      }

      .icon {
        font-size: 1.5rem;
      }
      p {
        font-weight: 800;
        @media (max-width: 800px) {
          font-size: 0.95rem;
        }
      }
    }
  }
}
