.Event-container {
  position: relative;

  .Grid-container-Pfp-container-title {
    font-size: 3rem;
    margin-bottom: 40px;

    @media (max-width: 900px) {
      font-size: 2rem;
    }

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }

    p {
      margin: 0;
      line-height: 4rem;
      text-transform: capitalize;
      width: 2ch;
      text-align: center;

      @media (max-width: 1600px) {
        width: unset;
        line-height: 1.5rem;
      }
    }
  }
  .progressBar {
    width: 100%;
  }
  .Pfp-container {
    margin-bottom: 60px;
    gap: 32px;

    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      grid-template-columns: repeat(1, minmax(300px, 1fr)) !important;
      gap: 24px;
    }

    .eventNftPreview {
      border-radius: var(--border-radius-large);
      overflow: hidden;
    }

    .info {
      @media (max-width: 800px) {
        max-height: unset;
        padding: unset;
      }
      h1 {
        font-size: 2.5rem;
        margin-bottom: 8px;
        @media (max-width: 800px) {
          font-size: 1.25rem;
          margin-bottom: 2px;
        }
      }
      h2 {
        font-size: 1.75rem;
        margin-bottom: 12px;
        @media (max-width: 800px) {
          font-size: 1.25rem;
          margin-bottom: 2px;
        }
      }
      p {
        font-size: 1rem;
        line-height: 1.75rem;
        margin-bottom: 32px;
        white-space: wrap;
        @media (max-width: 800px) {
          font-size: 0.95rem;
          line-height: unset;
          margin-bottom: 16px;
        }
      }
      .nftActionPanel {
        max-height: 570px;
        overflow-y: auto;
        grid-auto-rows: minmax(100px, auto);
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: unset;

        @media (max-width: 800px) {
          max-height: unset;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: var(--border-radius-medium);
        }
      }

      a,
      button {
        font-size: 1.2rem;
        font-weight: 600;
        flex: 1;
        min-width: 32%;
        padding: 32px;
        @media (max-width: 800px) {
          font-size: 1rem;
          padding: 24px 16px;
          white-space: wrap;
        }
      }

      button:disabled {
        color: var(--primary);
      }
      .clickable {
        display: flex;
        gap: 8px;
        background-color: var(--button-bg-color-dark);
      }
      .nftprofile_logo {
        width: 30px;
        height: 30px;
      }
      .clickable:hover {
        background-color: var(--button-bg-color-dark-hover);
      }
    }
    footer {
      margin-top: 16px;
    }
  }
  h1 {
    margin-bottom: 16px;
    @media (max-width: 800px) {
      margin-bottom: 10px;
    }
  }
  .Description-container {
    margin-bottom: 40px;
    font-size: 1rem;
    line-height: 1.7rem;
    color: var(--text-color-light);
    @media (max-width: 800px) {
      margin-bottom: 24px;
      font-size: 1rem;
      line-height: 1.35rem;
    }
  }
}
